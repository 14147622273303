/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base";
@import "app/theme/styles/admin";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";
@import '~primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import '~primeng/resources/primeng.min.css';
@import '~primeicons/primeicons.css';

.NgxEditor__Content p {
  // height: 17rem !important;
}

body {
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #ffffff;
    background: #96BC3B;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #f3f3f3;
    background: #96BC3B;

    :hover {
      border-color: #f3f3f3;
      background: #96BC3B;
    }
  }

  .text-primary {
    color: #96BC3B !important;
  }

  .fw-bold {
    font-weight: bold !important;
  }
}

.p-datatable .p-datatable-header {
  background: none !important;
  color: #212529;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 0rem 0rem !important;
}

.p-multiselect {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

#actualite-caroussel {
  .p-carousel-container {
    height: 11rem;
  }
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #96BC3B !important;
  color: #ffffff;
}

.p-progress-spinner-svg {
  height: 50% !important;
  width: 50% !important;
  margin: 0px !important;
}

.description-title {
  text-align: justify !important;
  font-size: 1.6rem !important;
  font-weight: bold !important;
  //  color: #96BC3B !important;
}

.description-contetent {
  text-align: justify !important;
  font-size: 1.2rem !important;
  // color: #96BC3B !important;
  margin-bottom: 15px !important;
}

.ng-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-right: 5px;
}

.ng-badge.success {
  background: #C8E6C9;
  color: #256029;
}

.ng-badge.indic {
  background: rgba(150, 188, 59, 0.13);
  color: #684A3A;
  text-transform: none !important;
  font-size: 1.3rem !important;
}

.ng-badge.danger {
  background: #FFCDD2;
  color: #C63737;
}

.ng-badge.warning {
  background: #FEEDAF;
  color: #8A5340;
}

.ng-badge.info {
  background: #B3E5FC;
  color: #23547B;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text-color p, h1 {
  color: #96BC3B !important;
  margin-bottom: 5px !important;
}

.ng-card {
  height: 100%;
  box-shadow: 10px 5px 5px 5px rgba(90, 139, 84, 0.2) !important;
}

.top-menu-toolbar {
  .app.green .top-toolbar .mat-toolbar-row {
    border-bottom: 0px !important;
  }
}

.NgxEditor__Content {
  height: 260px !important;
  overflow: auto !important;
}

.text-desc-style {
  text-align: justify !important;
  font-size: large !important;
  //font-weight: bold !important;
  font-family: "Times New Roman";
  line-height: 30px;
}

.text-titre {
  font-size: larger !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  //  color: #96bc3b !important;;
}

.ng-bg-header {
  background-color: #303030 !important;
  color: #f0f0f0 !important;

  a {
    color: #f0f0f0 !important;
  }
}

.map-tab {
  .mat-tab-labels {
    background-color: #96BC3B !important;
  }

  .mat-tab-label-active {
    background-color: #684A3A !important;
  }
}

.text-uppercase {
  text-transform: uppercase;
}
